<template>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <Loader v-bind:logo="loaderLogo"></Loader>

    <KTHeaderMobile></KTHeaderMobile>

    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
    >
      <KTAside></KTAside>

      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper full-height"
        id="kt_wrapper"
      >
        <KTHeader></KTHeader>

        <div
          class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          id="kt_content"
        >
          <div
            class="kt-container kt-grid__item kt-grid__item--fluid kt-container--fluid"
          >
            <transition name="fade-in-up">
              <router-view></router-view>
            </transition>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/components/aside/Aside.vue";
import KTHeader from "@/components/header/Header.vue";
import KTHeaderMobile from "@/components/header/HeaderMobile.vue";
import KTFooter from "@/components/footer/Footer.vue";
import Loader from "@/components/Loader.vue";
import HtmlClass from "@/common/htmlclass.service";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/store/htmlclass.module.js";

export default {
  name: "Product",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    Loader
  },
  methods: {},
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");
    // initialize html element classes
    HtmlClass.init();
  },
  mounted() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    }
  }
};
</script>
